@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0px !important;
    padding: 0;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 4px;
    height: 80px;
}
::-webkit-scrollbar-track {
    background: #222A35;
}
::-webkit-scrollbar-thumb {
    background: #575c66;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: #626970;
}
